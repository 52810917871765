import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, inject, InjectionToken } from '@angular/core';
import {
  AbilityBuilder,
  createMongoAbility,
  ExtractSubjectType,
  MongoAbility,
  MongoQuery,
  SubjectRawRule
} from "@casl/ability";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { EMPTY, pipe, tap } from "rxjs";
import { switchMap } from "rxjs/operators";
import { tapResponse } from "@ngrx/operators";
import { withRequestStatus } from "src/app/shared/state/request-status.feature";
import { Action, BackofficeUser, Subject } from 'src/app/shared/graphql';
import { AccountService } from "../services/account.service";
import {
  CLUB_DETAILS_FACADE_TOKEN,
  IClubDetailsFacade
} from "../../../interfaces/club-details-facade.interface";
import { checkAbility } from "../../../auth/auth.utils";
import { GlobalLoadingIndicatorService } from "../../../global-loading-indicator/global-loading-indicator.service";
import { ClubMgr } from "src/app/domains/club";


interface AccountState {
  userId: string | null;
  user: BackofficeUser | null;
  ability: { can: Function, rules: MongoAbility['rules'] },
  selectedClubId: string | null;
  currentClub: ClubMgr | null;
}

const ACCOUNT_STATE = new InjectionToken<AccountState>('AccountState', {
  factory: () => {
    const ability = new AbilityBuilder(createMongoAbility);
    return {
      userId: null,
      user: null,
      ability: { can: ability.can, rules: ability.rules },
      selectedClubId: null,
      currentClub: null,
    }
  }
});

export const AccountStore = signalStore(
  { providedIn: 'root' },
  withState(() => inject(ACCOUNT_STATE)),
  withRequestStatus('userRequest'),
  withRequestStatus('permissionsRequest'),
  withRequestStatus('clubDetailsRequest'),
  withMethods((store) => ({
    initialize(userId: string) {
      patchState(store, { userId });
    },
    selectAccount(clubId: string | null) {
      if (clubId === store.selectedClubId()) {
        return;
      }
      patchState(store, { selectedClubId: clubId, currentClub: null });
    },
    checkAbility: (action: Action, subject: Subject) => computed(() => checkAbility(store.ability().rules, action, subject)),
  })),
  withMethods((store, accountService = inject(AccountService), clubDetailsFacade: IClubDetailsFacade = inject(CLUB_DETAILS_FACADE_TOKEN), globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService)) => ({
    loadUser: rxMethod<string | null>(
      pipe(
        switchMap((query) => {
          if (!query) {
            return EMPTY;
          }

          store.setUserRequestPending();
          globalLoadingIndicatorService.registerTask({ id: 'loadUser', timeout: 10000 });

          return accountService.fetchOwnAccount().pipe(
            tapResponse({
              next: (user) => {
                patchState(store, { user });
                store.setUserRequestFulfilled();
                globalLoadingIndicatorService.setTaskFulfilled('loadUser');

                if (user?.clubs) {
                  const clubId = user.clubs[0].clubId;
                  store.selectAccount(clubId);
                  patchState(store, { selectedClubId: clubId }); // currentClub: user.clubs[0].club
                }
                console.log('loadUser', user);
              },
              error: (err) => {
                console.error(err);
                store.setUserRequestError(err as any);
                globalLoadingIndicatorService.setTaskError('loadUser', err as any);
              },
            })
          );
        })
      )),
    loadPermissions: rxMethod<{userId: string | null, clubId: string | null}>(
      pipe(
        switchMap(({ userId, clubId}) => {
          if (!userId) {
            return EMPTY;
          }

          console.log('loadPermissions', userId, clubId);

          store.setPermissionsRequestPending();
          globalLoadingIndicatorService.registerTask({ id: 'loadPermissions', timeout: 10000 });
          return accountService.fetchOwnPermissions().pipe(
            tapResponse({
              next: (permissions) => {
                const { can, rules } = new AbilityBuilder(createMongoAbility);
                permissions.forEach(p => {
                  can(p.action, p.subject);
                });
                patchState(store, { ability: { can: can, rules: rules } });
                store.setPermissionsRequestFulfilled();
                console.log('loadPermissions', permissions);
                globalLoadingIndicatorService.setTaskFulfilled('loadPermissions');
              },
              error: (err) => {
                console.error(err);
                store.setPermissionsRequestError(err as any);
                globalLoadingIndicatorService.setTaskError('loadPermissions', err as any);
              },
            })
          );
        })
      )),
    loadClubDetails: rxMethod<string | null>(
      pipe(
        switchMap((clubId) => {
          // const clubId = store.selectedClubId();
          if (!clubId) {
            return EMPTY;
          }

          console.log('loadClubDetails', clubId);

          store.setClubDetailsRequestPending();
          globalLoadingIndicatorService.registerTask({ id: 'loadClubDetails', timeout: 10000 });

          return clubDetailsFacade.getClubById(clubId).pipe(
            tapResponse({
              next: (club) => {
                patchState(store, { currentClub: club });
                store.setClubDetailsRequestFulfilled();
                globalLoadingIndicatorService.setTaskFulfilled('loadClubDetails');
                console.log('loadClubDetails', club);
              },
              error: (err) => {
                console.error(err);
                store.setClubDetailsRequestError(err as any);
                globalLoadingIndicatorService.setTaskError('loadClubDetails', err as any);
              },
            })
          );
        })
      )),
    updateCurrentClub: (club: ClubMgr) => patchState(store, { currentClub: club }),
  })),
  withComputed((state) => ({
    loadedUserIdWithClubId: computed(() => ({ userId: state.user()?.id ?? null, clubId: state.selectedClubId() })),
    abilityRules: computed<MongoAbility['rules']>(() => state.ability.rules()),
    timezone: computed(() => (state.user()?.ianaTimezone ? state.user()!.ianaTimezone as string : Intl.DateTimeFormat().resolvedOptions().timeZone)), // Intl.DateTimeFormat().resolvedOptions().timeZone // 'Europe/Athens'
  })),
  withHooks({
    onInit(store) {
      store.loadUser(store.userId);
      store.loadPermissions(store.loadedUserIdWithClubId);
      store.loadClubDetails(store.selectedClubId);
    }
  })
);
