import { computed, Signal } from "@angular/core";
import { patchState, SignalStoreFeature, signalStoreFeature, withComputed, withMethods, withState } from "@ngrx/signals";

export type RequestStatus = "idle" | "pending" | "fulfilled" | { error: string };

export type RequestStatusState = { requestStatus: RequestStatus };
export type RequestStatusStateComputed = {
  isPending: Signal<boolean>;
  isIdle: Signal<boolean>;
  isFulfilled: Signal<boolean>;
  hasError: Signal<boolean>;
  error: Signal<string | null>;
};
export type RequestStatusMethods = {
  setPending: () => void;
  setFulfilled: () => void;
  setError: (error: string) => void;
  setIdle: () => void;
};

export type NamedRequestStatusState<Prop extends string> = {
  [K in Prop as `${K}RequestStatus`]: RequestStatus;
};

export type NamedRequestStatusStateComputed<Prop extends string> = {
  [K in Prop as `is${Capitalize<K>}Pending`]: Signal<boolean>;
} & {
  [K in Prop as `is${Capitalize<K>}Fulfilled`]: Signal<boolean>;
} & {
  [K in Prop as `has${Capitalize<K>}Error`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Error`]: Signal<string | null>;
};

export type NamedRequestStatusMethods<Prop extends string> = {
  [K in Prop as `set${Capitalize<K>}Pending`]: () => void;
} & {
  [K in Prop as `set${Capitalize<K>}Fulfilled`]: () => void;
} & {
  [K in Prop as `set${Capitalize<K>}Error`]: (error: string) => void;
} & {
  [K in Prop as `set${Capitalize<K>}Idle`]: () => void;
};

function getRequestStatusKeys(prop?: string) {
  if (!prop) {
    return {
      requestStatusKey: `requestStatus`,
      isPendingKey: `isPending`,
      isFulfilledKey: `isFulfilled`,
      hasErrorKey: `hasError`,
      errorKey: `error`,
    };
  }

  const upperCaseProp = prop[0].toUpperCase() + prop.slice(1);

  return {
    requestStatusKey: `${prop}RequestStatus`,
    isPendingKey: `is${upperCaseProp}Pending`,
    isFulfilledKey: `is${upperCaseProp}Fulfilled`,
    hasErrorKey: `has${upperCaseProp}Error`,
    errorKey: `${prop}Error`,
  };
}

function getRequestStatusMethods(prop?: string) {
  if (!prop) {
    return {
      setIdleMethod: `setIdle`,
      setPendingMethod: `setPending`,
      setFulfilledMethod: `setFulfilled`,
      setErrorMethod: `setError`,
    };
  }

  prop = prop[0].toUpperCase() + prop.slice(1);

  return {
    setIdleMethod: `set${prop}Idle`,
    setPendingMethod: `set${prop}Pending`,
    setFulfilledMethod: `set${prop}Fulfilled`,
    setErrorMethod: `set${prop}Error`,
  };
}

export function withRequestStatus(): SignalStoreFeature<
  { state: {}; computed: {}; methods: {}, signals: {} },
  {
    state: RequestStatusState;
    computed: RequestStatusStateComputed;
    methods: RequestStatusMethods;
    signals: RequestStatusStateComputed;
  }
>;
export function withRequestStatus<Prop extends string>(
  prop?: Prop
): SignalStoreFeature<
  { state: {}; computed: {}; methods: {}, signals: {} },
  {
    state: NamedRequestStatusState<Prop>;
    computed: NamedRequestStatusStateComputed<Prop>;
    methods: NamedRequestStatusMethods<Prop>;
    signals: NamedRequestStatusStateComputed<Prop>;
  }
>;
export function withRequestStatus<Prop extends string>(prop?: Prop): SignalStoreFeature {
  const { requestStatusKey, isPendingKey, isFulfilledKey, hasErrorKey, errorKey } = getRequestStatusKeys(prop);
  const { setPendingMethod, setErrorMethod, setFulfilledMethod, setIdleMethod } = getRequestStatusMethods(prop);

  console.log(requestStatusKey, isPendingKey, isFulfilledKey, hasErrorKey, errorKey);

  return signalStoreFeature(
    withState({ [requestStatusKey]: "idle" as RequestStatus }),
    withComputed((state: Record<string, Signal<unknown>>) => {
      const requestState = state[requestStatusKey] as Signal<RequestStatus>;

      return {
        [isPendingKey]: computed(() => requestState() === "pending"),
        [isFulfilledKey]: computed(() => requestState() === "fulfilled"),
        [hasErrorKey]: computed(() => {
          const status = requestState();
          return !!(typeof status === "object" && status.error);
        }),
        [errorKey]: computed(() => {
          const status = requestState();
          return typeof status === "object" ? status.error : null;
        }),
      };
    }),
    withMethods((state) => ({
      [setIdleMethod]() {
        patchState(state, setIdle(prop));
      },
      [setPendingMethod]() {
        patchState(state, setPending(prop));
      },
      [setFulfilledMethod]() {
        patchState(state, setFulfilled(prop));
      },
      [setErrorMethod](error: string) {
        patchState(state, setError(error, prop));
      },
    }))
  );
}

export function setIdle<Prop extends string>(prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: "idle" } as NamedRequestStatusState<Prop>;
  }

  return { requestStatus: "idle" } as RequestStatusState;
}

export function setPending<Prop extends string>(prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: "pending" } as NamedRequestStatusState<Prop>;
  }

  return { requestStatus: "pending" } as RequestStatusState;
}

export function setFulfilled<Prop extends string>(prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: "fulfilled" } as NamedRequestStatusState<Prop>;
  }

  return { requestStatus: "fulfilled" } as RequestStatusState;
}

export function setError<Prop extends string>(error: string, prop?: Prop): NamedRequestStatusState<Prop> | RequestStatusState {
  if (prop) {
    return { [`${prop}RequestStatus`]: { error: error } } as NamedRequestStatusState<Prop>;
  }

  return { requestStatus: { error: error } } as RequestStatusState;
}
