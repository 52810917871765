import jwtDecode, {JwtPayload} from "jwt-decode";
import { Action, Subject } from "../graphql";
import { ExtractSubjectType, MongoAbility, MongoQuery, SubjectRawRule } from "@casl/ability";

export function isTokenValid(token: string = ''): boolean {
  if (token === '') return false;

  return true

  /*const decoded = jwtDecode<JwtPayload>(token);
  if (!decoded.exp) return false;

  return decoded.exp * 1000 >= new Date().getTime()*/
}

export function checkAbility(rules: MongoAbility['rules'], action: Action, subject: Subject): boolean {
  const hasSpecificPermission = rules.some(rule => rule.action === action && rule.subject === subject);
  const hasManagePermission = rules.some(rule => rule.action === 'MANAGE' && rule.subject === subject);

  return hasSpecificPermission || hasManagePermission;
}
