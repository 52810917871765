import { inject, Injectable } from '@angular/core';
import { map, Observable } from "rxjs";
import { Action, BackofficeUser, BackofficeUserSelfGQL, PermissionListOwnGQL, Subject } from "src/app/shared/graphql";
import { mapApolloQueryResultDataOrThrowOnError } from "../../../graphql/graphql-utils";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  #backofficeUserSelfGQL = inject(BackofficeUserSelfGQL);
  #permissionListOwnGQL = inject(PermissionListOwnGQL);

  fetchOwnAccount(): Observable<BackofficeUser> {
    return this.#backofficeUserSelfGQL.fetch().pipe(
      mapApolloQueryResultDataOrThrowOnError(),
      map((data) => {
        return data.backofficeUserSelf as BackofficeUser;
      })
    );
  }

  fetchOwnPermissions(): Observable<{ id: string, action: Action, subject: Subject}[]> {
    return this.#permissionListOwnGQL.fetch().pipe(
      mapApolloQueryResultDataOrThrowOnError(),
      map((data) => data.permissionListOwn));
  }
}
