import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';

export type TaskStatus = "pending" | "fulfilled" | { error: string };

export interface IAsyncTask {
  id: string;
  retryHandler?: () => any;
  timeout?: number;
}

export interface IAsyncLoadingTask extends IAsyncTask {
  status: TaskStatus;
  startedAt?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingIndicatorService {

  private _tasks: WritableSignal<Record<string, IAsyncLoadingTask>> = signal({});
  private _taskTimeouts: Record<string, any> = {};

  get tasks(): Signal<Record<string, IAsyncLoadingTask>> {
    return this._tasks;
  }

  registerTask(task: IAsyncTask): void {
    console.log(`[GlobalLoadingIndicatorService] Registering task ${task.id}`);
    this._tasks.set({
      ...this._tasks(),
      [task.id]: {
        id: task.id,
        status: "pending"
      }
    });

    const updatedTask = this._tasks()[task.id];
    this._setupTimeout(updatedTask);
  }

  unregisterTask(taskId: string): void {
      const tasks = this._tasks();
      delete tasks[taskId];
      this._tasks.set(tasks);
  }

  setTaskPending(taskId: string): void {
    this.setTaskStatus(taskId, "pending");
  }

  setTaskFulfilled(taskId: string): void {
    this.setTaskStatus(taskId, "fulfilled");
  }

  setTaskError(taskId: string, error: string): void {
    this.setTaskStatus(taskId, { error });
  }

  setTaskStatus(taskId: string, status: TaskStatus): void {
    const task = this._tasks()[taskId];
    if (!task) {
      console.warn(`[GlobalLoadingIndicatorService] Task with id ${taskId} not found`);
      return;
    }
    const updatedTask = {
      ...task,
      status
    };

    if (status === "pending") {
      updatedTask.startedAt = Date.now();
      this._setupTimeout(updatedTask);
    }

    this._tasks.set({
      ...this.tasks(),
      [taskId]: updatedTask
    });
  }

  private _setupTimeout(task: IAsyncLoadingTask) {
    // Clear any existing timeout
    if (this._taskTimeouts[task.id]) {
      clearTimeout(this._taskTimeouts[task.id]);
      delete this._taskTimeouts[task.id];
    }

    // Set a new timeout if the task is not yet complete or in error
    if (task.status == 'pending' && task.timeout) {
      this._taskTimeouts[task.id] = setTimeout(() => {
        console.error(`[AsyncInitializationService] [${task.id}] Timed out`);
        this.setTaskError(task.id, 'timeout');
      }, task.timeout);
    }
  }

  _isAnyTaskPending = computed(() => {
    const result = Object.values(this._tasks()).some(task => task.status === 'pending');
    console.log('isAnyTaskPending', result);
    return result;
  });

  get isAnyTaskPending(): Signal<boolean> {
    return this._isAnyTaskPending;
  }

  _isAnyTaskError = computed(() => {
    return Object.values(this._tasks()).some(task => typeof task.status === 'object');
  });

  get isAnyTaskError(): Signal<boolean> {
    return this._isAnyTaskError;
  }

  _combinedTasksStatus = computed<'pending' | 'fulfilled' |'error'>(() => {
    const tasks = this._tasks();
    if (Object.values(tasks).some(task => typeof task.status === 'object')) {
      return 'error';
    }
    if (Object.values(tasks).every(task => task.status === 'fulfilled')) {
      return 'fulfilled';
    }
    return 'pending';
  });

  get combinedTasksStatus(): Signal<'pending' | 'fulfilled' | 'error'> {
    return this._combinedTasksStatus;
  }
}
