import { ApolloQueryResult } from '@apollo/client/core';
import { map, Observable } from "rxjs";
import { MutationResult } from "apollo-angular";

export function mapApolloQueryResultDataOrThrowOnError<T>() {
  return (source: Observable<ApolloQueryResult<T>>): Observable<T> => {
    return source.pipe(
      map(response => {
        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message);
        }
        if ('error' in response && response.error) {
          throw new Error(response.error.message);
        }
        if (response.data == null) {
          throw new Error('No data returned');
        }
        return response.data;
      })
    );
  };
}

export function mapApolloMutationResultDataOrThrowOnError<T>() {
  return (source: Observable<MutationResult<T>>): Observable<T> => {
    return source.pipe(
      map(response => {
        if (response.errors && response.errors.length > 0) {
          throw new Error(response.errors[0].message);
        }
        if (response.data == null) {
          throw new Error('No data returned');
        }
        return response.data;
      })
    );
  };
}
